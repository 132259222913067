import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { LoaderService } from './loader.service';
import { LoaderState } from './loader.model';
import { NgProgressComponent } from '@ngx-progressbar/core';

@Component({
    selector: 'app-loader',
    templateUrl: 'loader.component.html',
    styleUrls: ['loader.component.less']
})
export class LoaderComponent implements OnInit {
    show = false;
    private subscription: Subscription;
    @ViewChild(NgProgressComponent) progressBar: NgProgressComponent;
    constructor(
        private loaderService: LoaderService,
        private cdr: ChangeDetectorRef
    ) { }
    ngOnInit() {
        this.subscription = this.loaderService.loaderState.subscribe((state: LoaderState) => {
            if (state.show) {
                this.progressBar.start();
            }
            else {
                this.progressBar.complete();
            }
            this.show = state.show;
            this.cdr.detectChanges();
        });
    };
    ngOnDestroy() {
        this.subscription.unsubscribe();
    }
}