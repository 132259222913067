import { Component, OnInit, TemplateRef, Input } from '@angular/core';
import { NzModalService } from 'ng-zorro-antd';

@Component({
    selector: 'app-shared-agreement-declaration',
    templateUrl: './declaration.component.html',
    styleUrls: ['./declaration.component.less']
})
export class SharedAgreementDeclarationComponent implements OnInit {
    @Input() type: number;
    constructor(private modalSrv: NzModalService) { }

    ngOnInit(): void { }

    showAgreement(tplContent: TemplateRef<{}>) {
        this.modalSrv.create({
            nzContent: tplContent,
            nzMaskClosable: true,
            nzWidth: 1000,
            nzFooter: null
        });
    }
}
