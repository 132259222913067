import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoaderComponent, LoaderService } from './loader';
import { NgProgressModule } from '@ngx-progressbar/core';

@NgModule({
    declarations: [
        LoaderComponent
    ],
    imports: [
        CommonModule,
        NgProgressModule
    ],
    exports: [
        LoaderComponent
    ],
    providers: [
        LoaderService
    ],
})
export class CoreModule { }