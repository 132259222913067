import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { PathsUtil } from '../../../util/paths';

@Injectable()
export class RegisterService {
    constructor(private http: HttpClient) {

    }
    check(phone) {
        return this.http.post(PathsUtil.user_check, { what: 'tel', value1: 86, value2: phone });
    }
    register(data) {
        return this.http.post(PathsUtil.user_regist, data);
    }
}