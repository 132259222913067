// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
import { AESPrefix } from '../app/util/aes-branch/prod/prefix';
import { AESCentral } from '../app/util/aes-branch/prod/central';
import { AESSuffix } from '../app/util/aes-branch/prod/suffix';

export const environment = {
    production: false,
    host: 'https://api.windpayer.com/client/',
    bfc_host: 'https://bfc.windpayer.com/',
    //  host: 'http://sandbox-api.windpayer.com/client/',
    //  bfc_host: 'http://sandbox-bfc.windpayer.com/',
    // host: 'http://localhost:3031/client/',
    // bfc_host: 'http://localhost:3031/',
    AESKEY: AESPrefix.KeyPrefix + AESCentral.KeyCentral + AESSuffix.KeySuffix,
    AESIV: AESPrefix.IVPrefix + AESCentral.IVCentral + AESSuffix.IVSuffix
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
