import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedAgreementDeclarationComponent } from './declaration/declaration.component';
import { SharedAgreementAmazonDeclarationComponent } from './amazondeclaration/amazondeclaration.component';
import { SharedAgreementGuidComponent } from './guid/guid.component';
import { SharedAgreementPrivacyComponent } from './privacy/privacy.component';
import { SharedAgreementServeComponent } from './serve/serve.component';
import { SharedAgreementCrossPayServiceComponent } from './crosspayservice/crosspayservice.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgZorroAntdModule } from 'ng-zorro-antd';

@NgModule({
    declarations: [
        SharedAgreementDeclarationComponent,
        SharedAgreementGuidComponent,
        SharedAgreementPrivacyComponent,
        SharedAgreementServeComponent,
        SharedAgreementCrossPayServiceComponent,
        SharedAgreementAmazonDeclarationComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        NgZorroAntdModule
    ],
    exports: [
        SharedAgreementDeclarationComponent,
        SharedAgreementGuidComponent,
        SharedAgreementPrivacyComponent,
        SharedAgreementServeComponent,
        SharedAgreementCrossPayServiceComponent,
        SharedAgreementAmazonDeclarationComponent
    ],
    entryComponents: []
})
export class SharedAgreementModule { }