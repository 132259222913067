// import { Md5 } from "ts-md5/dist/md5";
import { Md5 } from 'ts-md5';
import { AESTicket } from './aes-ticket';

export class Sign {
    constructor() {

    }
    public static GetSign(body: any, nonce?: number) {
        if (nonce) {
            body.nonce = nonce;
        }
        let secret = AESTicket.Descrypt(localStorage.getItem('s'));
        let obj = Object.assign({}, body);
        let keysSort = Object.keys(obj).sort();
        let str: string = '';
        for (var ki in keysSort) {
            if (obj[keysSort[ki]] !== undefined && obj[keysSort[ki]] !== null && obj[keysSort[ki]] !== '' && keysSort[ki] !== 'sign') {
                let key = keysSort[ki];
                let value = obj[keysSort[ki]];
                if (value === true) {
                    value = 'True';
                }
                if (value === false) {
                    value = 'False';
                }
                if (typeof (value) === 'object') {
                    value = JSON.stringify(value);
                }
                str += key + '=' + value + '&';
            }
        }
        str += 'secret=' + secret;
        return Md5.hashStr(str).toString().toUpperCase();
    }
    public static VerifySign(body: any, secret: string) {
        let sign = this.GetSign(body);
        return body.sign === sign;
    }
}