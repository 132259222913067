import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CacheService } from '../../providers/cache.service';
import { GlobalService } from 'src/app/providers/global.service';

@Component({
    selector: 'app-layout-default',
    templateUrl: './default.component.html',
    styleUrls: ['./default.component.less']
})
export class LayoutDefaultComponent implements OnInit {
    currentPath: string = 'home';//根据当前url确定选择的目录
    userInfo: any = {
        account: '',
        icon: '../../../assets/imgs/head.png',
        msg: 0,
        name: '',
        status: { id: 1, name: '正常' },
        verifyStatus: { id: 0, name: "未认证" },
        tel: '',
        uid: ''
    };
    constructor(
        private router: Router,
        private cacheSrv: CacheService,
        private globalSrv: GlobalService
    ) {
        this.globalSrv.userInfoChangedEvent.subscribe((userInfo: any) => {
            this.userInfo = userInfo;
        });
    }

    ngOnInit(): void {
        this.globalSrv.getInfo().subscribe((resp: any) => {
            if (resp.ok) {
                this.userInfo = resp.data;
                this.globalSrv.userInfo = resp.data;
                this.globalSrv.verifyCertificationStatus();
            }
        });
    }
    jumpBc() {
        window.location.href='https://bc.windpayer.com'
    }

    logout() {
        this.router.navigateByUrl("/passport/login").then(() => {
            this.cacheSrv.clear();
        });
    }
}