import { Component, OnInit } from '@angular/core';
import { FullScreenEbayAuthService } from './ebayauth.service';
import { CacheService } from 'src/app/providers/cache.service';

@Component({
    selector: 'app-fullscreen-ebayauth',
    templateUrl: './ebayauth.component.html',
    styleUrls: ['./ebayauth.component.less'],
    providers: [FullScreenEbayAuthService]
})
export class FullScreenEbayAuthComponent implements OnInit {
    isSpinning: boolean = true;
    isSuccess: boolean = false;
    constructor(
        private cacheService: CacheService,
        private srv: FullScreenEbayAuthService
    ) { }

    ngOnInit(): void {
        let sid = this.cacheService.getCache('sid');
        let accountID = this.cacheService.getCache('accountID');
        console.log("ebayAuth.html;sid:" + sid + "**** accountID:" + accountID);
        if (sid) {
            this.srv.eBayUserToken({
                sid: sid,
                accountID:accountID
            }).subscribe((resp: any) => {
                this.isSpinning = false;
                if (resp.ok) {
                    this.isSuccess = true;
                }
            }, () => {
                this.isSpinning = false;
            });
        }
    }
}
