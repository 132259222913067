import { environment } from '../../environments';

export class AESTicket {
    private static KEY: string = environment.AESKEY;
    private static IV: string = environment.AESIV;

    public static Descrypt(ticket: string): any {
        if (ticket) {
            try {
                var iv = CryptoJS.enc.Utf8.parse(this.IV);
                var key = CryptoJS.enc.Utf8.parse(this.KEY);
                var cfg = {
                    mode: CryptoJS.mode.CBC,
                    iv: iv,
                    padding: CryptoJS.pad.Pkcs7
                };
                let bearer = CryptoJS.AES.decrypt(ticket, key, cfg).toString(CryptoJS.enc.Utf8);
                return bearer;
            }
            catch (err) {
                return null;
            }
        }
        return null;
    }

    public static Encrypt(input: string) {
        if (input) {
            try {
                var iv = CryptoJS.enc.Utf8.parse(this.IV);
                var key = CryptoJS.enc.Utf8.parse(this.KEY);
                var encrypted = CryptoJS.AES.encrypt(input, key,
                    {
                        iv: iv,
                        mode: CryptoJS.mode.CBC,
                        padding: CryptoJS.pad.Pkcs7
                    });
                return encrypted.toString();   //返回的是base64格式的密文
            }
            catch (err) {
                return key;
            }
        }
    }
}