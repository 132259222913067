export class PathsUtil {

    public static user_check: string = 'user/check/';
    public static user_code: string = 'user/code';
    public static user_regist: string = 'user/regist/';
    public static user_getinfo: string = 'user/getinfo';
    public static user_signin: string = 'user/signin';
    public static user_getqxs: string = 'user/getqxs';
    public static user_getnewmessage: string = 'user/getnewmessage';
    public static user_uploadicon: string = 'user/uploadicon';
    public static user_uploadidcard: string = 'user/uploadidcard';
    public static user_personinfo: string = 'user/personinfo';
    public static user_setdefaultbzdm: string = 'user/setdefaultbzdm';
    public static user_certification: string = 'user/certification';
    public static user_changetel: string = 'user/changetel';
    public static user_changeemail: string = 'user/changeemail';
    public static user_changepwd: string = 'user/changepwd';
    public static user_checkpwd: string = 'user/checkpwd';
    public static user_checkaccount: string = 'user/checkaccount';//验证账号是否存在
    public static user_listsq: string = 'user/listsq';//获取密保问题
    public static user_saveqa: string = 'user/saveqa';//设置密保答案
    public static user_userlistsq: string = 'user/userlistsq';//获取密保问题
    public static user_verifyqa: string = 'user/verifyqa';//验证答案
    public static user_resetpwd: string = 'user/resetpwd';//重置密码
    public static user_updatepwd: string = 'user/updatepwd';//更新密码
    public static user_getmyapps: string = 'user/getmyapps';//获取我的应用
    public static user_getmybankaccounts: string = 'user/getmybankaccounts';//获取我的应用下的收款账号列表
    public static user_exportmybankaccounts: string = 'user/exportmybankaccounts';//导出我的应用下的收款账号列表

    public static user_listUserEnterprise: string = 'user/listuserenterprise';//检索企业信息
    public static user_saveuserenterprise: string = 'user/saveuserenterprise';//新增/修改企业信息
    public static user_getmybankaccountdetail: string = 'user/getmybankaccountdetail';//获取收款账号详情
    public static user_listbankaccount: string = 'user/listbankaccount';//获取收款账号列表
    public static user_listbankcard: string = 'user/listbankcard';//获取提现银行卡
    public static user_listtransferbankcard: string = 'user/listtransferbankcard';//获取转账银行卡
    public static user_savetransferbankcard: string = 'user/savetransferbankcard';//保存转账银行卡
    public static user_getbzdms: string = 'user/getbzdms';//获取币种代码
    public static user_getbanks: string = 'user/getbanks';//根据银行卡所在地获取银行列表
    public static user_getbankbranchinfo: string = 'user/getbankbranchinfo';//根据银行卡所在地获取对应的银行信息
    public static user_canaddbankcard: string = 'user/canaddbankcard';//判断是否允许添加银行卡
    public static user_savebankcard: string = 'user/savebankcard';//新增/修改 提现银行卡
    public static user_getwithdrawcashbzdms: string = 'user/getwithdrawcashbzdms';//获取提现金额币种
    public static user_listbatchwithdrawcash: string = 'user/listbatchwithdrawcash';//获取批量提现信息
    public static user_selectbatchwithdrawcash: string = 'user/selectbatchwithdrawcash';//选择批量提现信息
    public static user_selectwithdrawcash: string = 'user/selectwithdrawcash';//单笔提现信息
    public static user_commitwithdraw: string = 'user/commitwithdraw';//提现
    public static user_uploadfile: string = 'user/uploadfile';//上传文件
    public static user_getrenewalamount: string = 'user/getrenewalamount';//获取续费金额
    public static user_listthirdpartybankcard: string = 'user/listthirdpartybankcard';//获取第三方转账收款人
    public static user_listb2bbankaccount: string = 'user/listb2bbankaccount';//获取b2b付款账号
    public static user_listb2cbankaccount: string = 'user/listb2cbankaccount';//获取b2c付款账号
    public static user_gettransferbzdm: string = 'user/gettransferbzdm';//获取转账币种代码
    public static user_issignenterprise: string = 'user/issignenterprise';//是否有企业主体
    public static user_commitb2btransfer: string = 'user/commitb2btransfer';//转账提交
    public static user_uploadfile2oss: string = 'user/uploadfile2oss';//上传文件到oss
    public static user_commitb2ctransfer: string = 'user/commitb2ctransfer';//供应商转账
    public static user_supplierqaaudit: string = 'user/supplierqaaudit';//资质审核
    public static user_listsupplierbankcard: string = 'user/listsupplierbankcard';//获取供应商收款账号
    public static user_listoffshoreenterprise: string = 'user/listoffshoreenterprise';//获取企业主体列表
    public static user_setbankcardapp: string = 'user/setbankcardapp';//设置提现银行卡应用
    public static user_settransferbankcardapp: string = 'user/settransferbankcardapp';//设置转账银行卡应用
    public static user_getfilterapps: string = 'user/getfilterapps';//获取客户已申请应用
    public static user_gettradecountry: string = 'user/gettradecountry';//获取b2b主要贸易国家或区域
    public static user_getcountrycode: string = 'user/getcountrycode';//获取国家代码
    public static user_listvatbankaccount: string = 'user/listvatbankaccount';//获取vat缴费付款账号
    public static user_getvatcountry: string = 'user/getvatcountry';//获取vat缴费国家
    public static user_getskyeehlandvatfee: string = 'user/getskyeehlandvatfee';//获取汇率和vat手续费
    public static user_commitvat: string = 'user/commitvat';//提交vat缴费
    public static user_uploadordertemplate: string = 'user/uploadordertemplate';//上传订单模板
    public static user_setappremark: string = 'user/setappremark';//设置应用备注
    public static user_getBankAccountStatus: string = 'user/getbankaccountstatus';//获取收款账号状态
    public static user_unbindbankcard: string = 'user/unbindbankcard';//解绑银行卡应用
    public static user_deletebankcard: string = 'user/deletebankcard';//删除银行卡
    public static user_loadbankcardbranchname: string = 'user/loadbankcardbranchname';//加载支行名称
    public static user_getuseraminfo: string = 'user/getuseraminfo';//获取所属销售
    public static user_loginRichCard: string = 'user/loginRichCard';//登录丰富卡系统

    public static sms_sendverifycode: string = 'sms/sendVerifyCode';
    public static sms_sendmailverifycode: string = 'sms/sendmailverifycode';

    public static app_getapps: string = 'app/getapps';
    public static app_getaccountenterprise: string = 'app/getaccountenterprise';//获取账号主体企业
    public static app_getstorecataloies: string = 'app/getstorecataloies';//获取店铺销售类目
    public static app_getannualsalesvolumes: string = 'app/getannualsalesvolumes';//获取店铺预计年销售额下拉框数据
    public static app_getdeveloperinfo: string = 'app/getdeveloperinfo';//获取开发者信息
    public static app_saveamzappapply: string = 'app/saveamzappapply'; //亚马逊应用申请
    public static app_getsitebzdms: string = 'app/getsitebzdms';//获取站点和对应币种代码
    public static app_saveebayappapply: string = 'app/saveebayappapply';//ebay应用申请
    public static app_savepaypalappapply: string = 'app/savepaypalappapply';//paypal应用申请
    public static app_getworkflowresult: string = 'app/getworkflowresult';//获取工作流处理结果
    public static app_getredirecturl: string = 'app/getredirecturl';//获取ebay授权跳转地址
    public static app_ebayusertoken: string = 'app/ebayusertoken';//获取ebay授权信息
    public static app_getauthresult: string = 'app/getauthresult';//获取授权结果
    public static app_saveoffshoreappapply: string = 'app/saveoffshoreappapply';//申请离岸账户
    public static app_getindustry: string = 'app/getindustry';//获取行业信息
    public static app_getmonthsales: string = 'app/getmonthsales';//获取月交易额
    public static app_getmonthsalesquantity: string = 'app/getmonthsalesquantity';//获取月交易笔数
    public static app_getmaxsinglesales: string = 'app/getmaxsinglesales';//获取单笔最大交易额
    public static app_getappapplybyaid: string = 'app/getappapplybyaid';//获取应用申请记录
    public static app_listneedmoreappapply: string = 'app/listneedmoreappapply';//获取需要修改的应用申请
    public static app_saveairbnbappapply: string = 'app/saveairbnbappapply';//airbnb应用申请
    public static app_savewalmartappapply: string = 'app/savewalmartappapply';//walmart应用申请
    public static app_saveetsyappapply: string = 'app/saveetsyappapply';//etsy应用申请
    public static app_savemercariappapply: string = 'app/savemercariappapply';//mercari应用申请
    public static app_saveshopeeappapply: string = 'app/saveshopeeappapply';//shopee应用申请
    public static app_amazonverify: string = 'app/amazonverify';//亚马逊应用授权
    public static app_stopAccount: string = 'app/stopaccount';//停用账号
    public static app_getAuthUrl: string = 'app/getAuthUrl';//获取亚马逊授权地址
    public static app_getAuthResult: string = 'app/getAmazonAuthResult';//获取亚马逊授权结果

    public static transaction_getuserapps: string = 'transaction/getuserapps';//获取用户应用
    public static transaction_listtransaction: string = 'transaction/listtransaction';//检索交易记录
    public static transaction_gettransactionstatus: string = 'transaction/gettransactionstatus';//获取交易状态
    public static transaction_getplatformstore: string = 'transaction/getplatformstore';//获取平台店铺
    public static transaction_getconfirmdata: string = 'transaction/getconfirmdata';//获取到账确认信息
    public static transaction_getfundproperty: string = 'transaction/getfundproperty';//获取资金性质
    public static transaction_confirm: string = 'transaction/confirm';//到账确认
    public static transaction_uploadOrderFile: string = 'transaction/uploadorderfile';//到账确认导入订单Excel
    public static transaction_uploadLogisticsFile: string = 'transaction/uploadlogisticsfile';//到账确认导入物流文件
    public static transaction_exporttransaction: string = 'transaction/exporttransaction';//导出Excel

    public static dashboard_index: string = 'dashboard/index';//我的首页

    public static media_listnews: string = 'media/listnews';//检索新闻公告
    public static media_listmessage: string = 'media/listmessage';//检索站内信
    public static media_setmessagereaded: string = 'media/setmessagereaded';//设置消息已读

    public static pay_pay: string = 'pay/pay';//扫码支付
    public static pay_query: string = 'pay/query';//交易查询 
}