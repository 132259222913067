import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { PathsUtil } from '../util/paths';

@Injectable({
    providedIn: 'root'
})
export class VerificationService {
    constructor(private http: HttpClient) {

    }
    sendVerifyCode(params: any) {
        return this.http.post(PathsUtil.sms_sendverifycode, params);
    }
    sendEmailVerifyCode(email) {
        return this.http.post(PathsUtil.sms_sendmailverifycode, { to: email });
    }
}