import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { of } from 'rxjs';
import { delay } from 'rxjs/operators';
import { PathsUtil } from 'src/app/util/paths';

@Injectable()
export class FullScreenChangePwdService {
    constructor(private http: HttpClient) {

    }

    updatePwd(params) {
        return this.http.post(PathsUtil.user_updatepwd, params);
    }
}