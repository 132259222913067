import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { PathsUtil } from '../../../util/paths';

@Injectable()
export class LoginService {
    constructor(private http: HttpClient) {

    }
    login(data: any) {
        return this.http.post(PathsUtil.user_signin, data);
    }
}