import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-fullscreen-privacypolicy',
    templateUrl: './privacypolicy.component.html',
    styleUrls: ['./privacypolicy.component.less']
})
export class FullscreenPrivacyPolicyComponent implements OnInit {
    constructor() { }

    ngOnInit(): void { }
}
