import { Component, OnInit,ViewChild,ElementRef} from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { FullScreenForgetPwdService } from './forgetpwd.service';
import { NzMessageService } from 'ng-zorro-antd';
import { VerificationService } from '../../../providers/verification.service';
import { AESTicket } from 'src/app/util/aes-ticket';
import { Router } from '@angular/router';

@Component({
    selector: 'app-fullscreen-forgetpwd',
    templateUrl: './forgetpwd.component.html',
    styleUrls: ['./forgetpwd.component.less'],
    providers: [FullScreenForgetPwdService]
})
export class FullScreenForgetPwdComponent implements OnInit {
    current = 0;
    account: string = '';
    personInfo: any;//用户信息
    validateForm: FormGroup;
    passwordForm: FormGroup;
    retrievableType = 'phone';//密码取回方式
    code_second: number = 60;//再次获取验证码的间隔时间(s)
    private code_valid: string = '';//解析后的正确验证码
    private code_expried: number = 0;//验证码过期时间
    private interval: any;
    @ViewChild('checkCode') canvasRef: ElementRef;
    verifycode: string; //验证码
    inputVerifycode: string; //验证码
    showVerificationCode: boolean = false;
    flag:number = 0;
    constructor(
        private fb: FormBuilder,
        private srv: FullScreenForgetPwdService,
        private msgSrv: NzMessageService,
        private verifySrv: VerificationService,
        private router: Router
    ) { }

    ngOnInit(): void {
        this.validateForm = this.fb.group({
            code: ['', [Validators.required]],
            question: ['0', [Validators.required]],
            answer: ['', [Validators.required]]
        });
        this.passwordForm = this.fb.group({
            password: ['', [Validators.required, Validators.pattern(/^(?![\d]+$)(?![a-zA-Z]+$)(?![^\da-zA-Z]+$).{8,}$/)]],
            checkPassword: ['', [Validators.required, this.confirmationValidator]]
        });
    }

    checkAccount() {
        if (this.showVerificationCode) {
            if(this.inputVerifycode == undefined || this.inputVerifycode === '')
            {
                this.msgSrv.error("请输入验证码!");
                return false;
            }
            if(this.inputVerifycode.toLocaleLowerCase() !== this.verifycode.toLocaleLowerCase())
            {
                this.msgSrv.error("验证码错误!");
                this.clickChange();
                return false;
            }
            
        }
        this.srv.checkAccount({
            account: this.account
        }).subscribe((resp: any) => {
            if (resp.ok) {
                this.personInfo = resp.data;
                this.current++;
                this.flag = 0;
                this.inputVerifycode = "";
                this.showVerificationCode = false;
            }
            else{
                this.flag++;
                if(this.flag >= 3)
                {
                    this.showVerificationCode = true;
                    this.clickChange();
                    this.validateForm.get('code').setValidators(Validators.required);
                    this.validateForm.get('code').updateValueAndValidity();
                }
            }
        });
    }

    updateConfirmValidator(): void {
        Promise.resolve().then(() => this.passwordForm.controls.checkPassword.updateValueAndValidity());
    }

    confirmationValidator = (control: FormControl): { [s: string]: boolean } => {
        if (!control.value) {
            return { required: true };
        } else if (control.value !== this.passwordForm.controls.password.value) {
            return { confirm: true, error: true };
        }
    }

    getCode() {
        this.code_second = 59;
        this.interval = window.setInterval(() => {
            if (this.code_second <= 0) {
                window.clearInterval(this.interval);
                this.code_second = 60;
                return;
            }
            else {
                this.code_second--;
            }
        }, 1000);
        if (this.retrievableType === 'phone') {
            this.verifySrv.sendVerifyCode({
                what: 'ResetPwd_VerifyCode',
                tel: this.personInfo.tel
            }).subscribe((resp: any) => {
                if (resp.ok) {
                    this.descryptCode(resp.data);
                    return;
                }
            });
        }
        else {
            this.verifySrv.sendEmailVerifyCode(this.personInfo.email).subscribe((resp: any) => {
                if (resp.ok) {
                    this.descryptCode(resp.data);
                    return;
                }
            });
        }
    }

    descryptCode(ciphertext) {
        let data = JSON.parse(AESTicket.Descrypt(ciphertext));
        this.code_valid = data.code;
        this.code_expried = data.expried;
    }

    next() {
        window.clearInterval(this.interval);
        switch (this.retrievableType) {
            case 'phone':
                this.validateForm.get('code').reset();
                break;
            case 'email':
                this.validateForm.get('code').reset();
                break;
            default:
                this.validateForm.get('answer').reset();
                break;
        }
        this.current++;
    }

    valid() {
        let formData = this.validateForm.value;
        switch (this.retrievableType) {
            case 'phone':
                if (!this.validateForm.get('code').valid) {
                    this.validateForm.get('code').markAsDirty();
                    this.validateForm.get('code').updateValueAndValidity();
                    return false;
                }
                if (formData.code !== this.code_valid) {
                    this.msgSrv.error("验证码错误，请重新输入!");
                    return false;
                }
                this.current++;
                break;
            case 'email':
                if (!this.validateForm.get('code').valid) {
                    this.validateForm.get('code').markAsDirty();
                    this.validateForm.get('code').updateValueAndValidity();
                    return false;
                }
                if (formData.code !== this.code_valid) {
                    this.msgSrv.error("验证码错误，请重新输入!");
                    return false;
                }
                this.current++;
                break;
            default:
                if (!this.validateForm.get('question').valid) {
                    this.validateForm.get('question').markAsDirty();
                    this.validateForm.get('question').updateValueAndValidity();
                    return false;
                }
                else if (!this.validateForm.get('answer').valid) {
                    this.validateForm.get('answer').markAsDirty();
                    this.validateForm.get('answer').updateValueAndValidity();
                    return false;
                }
                else {
                    let qa = this.personInfo.sqa[this.validateForm.get('question').value];
                    if (qa.A === this.validateForm.get('answer').value) {
                        this.current++;
                    }
                    else {
                        this.msgSrv.error('安全问题回答错误!');
                    }
                }
                break;
        }
    }

    done(): void {
        this.srv.resetPwd({
            account: this.account,
            pwd: AESTicket.Encrypt(this.passwordForm.get('password').value),
            code_expried: this.code_expried
        }).subscribe((resp: any) => {
            if (resp.ok) {
                this.msgSrv.success("修改成功!");
                this.router.navigateByUrl('/passport/login');
            }
        })
    }


    public clickChange() {
        const cxt: CanvasRenderingContext2D = this.canvasRef.nativeElement.getContext('2d');
        cxt.fillStyle = '#fff';
        cxt.fillRect(0, 0, 80, 35);

        /*生成干扰线20条*/
        for (let j = 0; j < 20; j++) {
            cxt.strokeStyle = this.rgb();
            cxt.beginPath();    // 若省略beginPath，则每点击一次验证码会累积干扰线的条数
            cxt.moveTo(this.lineX(), this.lineY());
            cxt.lineTo(this.lineX(), this.lineY());
            cxt.lineWidth = 0.5;
            cxt.closePath();
            cxt.stroke();
        }

        cxt.fillStyle = '#6271a9';
        cxt.font = 'bold 20px Arial';
        cxt.fillText(this.createCode(), 15, 25);   // 把rand()生成的随机数文本填充到canvas中
    }

    // 创建随机码
    public createCode() {
        this.verifycode = '';
        const codeLength = 4;  // 验证码的长度
        const random = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9,
            'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z',
            'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z']; // 所有候选组成验证码的字符，当然也可以用中文的
        for (let i = 0; i < codeLength; i++) { // 循环操作
            const index = Math.floor(Math.random() * 52); // 取得随机数的索引（0~51）
            this.verifycode += random[index]; // 根据索引取得随机数加到code上
        }
        return this.verifycode;
    }

    /*干扰线的随机x坐标值*/
    public lineX() {
        const ranLineX = Math.floor(Math.random() * 80);
        return ranLineX;
    }

    /*干扰线的随机y坐标值*/
    public lineY() {
        const ranLineY = Math.floor(Math.random() * 35);
        return ranLineY;
    }

    // 生成随机颜色
    public rgb() {
        // 因为在angular4里面不能使用arguments，所以换了一种方法。
        return '#' + Math.floor(Math.random() * 16777215).toString(16);
    }
}
