import { NgModule } from '@angular/core';
import { LayoutDefaultComponent } from './default';
import { LayoutPassportComponent } from './passport';
import { LayoutFullScreenComponent } from './fullscreen';
import { SharedModule } from '../shared';
import { SharedPromptModule } from '../shared/components/prompt';
import { RouterModule } from '@angular/router';

@NgModule({
    declarations: [
        LayoutDefaultComponent,
        LayoutPassportComponent,
        LayoutFullScreenComponent
    ],
    imports: [
        RouterModule,
        SharedModule,
        SharedPromptModule
    ],
    exports: [
        LayoutDefaultComponent,
        LayoutPassportComponent,
        LayoutFullScreenComponent
    ],
    providers: [],
})
export class LayoutModule { }