import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { PathsUtil } from 'src/app/util/paths';
import { NzModalService } from 'ng-zorro-antd';
import { SharedPromptFrozenComponent } from '../shared/components/prompt/frozen';
import { SharedPromptCertificationComponent } from '../shared/components/prompt/certification';

@Injectable()
export class GlobalService {
    public userInfo: any;
    userInfoChangedEvent: EventEmitter<string> = new EventEmitter<string>();
    constructor(private http: HttpClient, private modalSrv: NzModalService) {

    }
    getInfo() {
        return this.http.get(PathsUtil.user_getinfo);
    }

    async getUserInfo() {
        return new Promise<any>((resolve) => {
            if (this.userInfo) {
                resolve(this.userInfo);
            }
            else {
                this.http.get(PathsUtil.user_getinfo).subscribe((resp: any) => {
                    if (resp.ok) {
                        resolve(resp.data);
                    }
                    else {
                        resolve({
                            id: 0,
                            name: '获取用户信息异常'
                        });
                    }
                });
            }
        })
    }

    verifyUserStatus(callback: Function) {
        this.getInfo().subscribe((resp: any) => {
            if (resp.ok) {
                this.userInfo = resp.data;
                if (this.userInfo.status.id === 2) {
                    this.modalSrv.create({
                        nzTitle: "信息提示",
                        nzContent: SharedPromptFrozenComponent,
                        nzOkText: null,
                        nzMaskClosable: false
                    });
                }
                else {
                    callback();
                }
            }
        })
    }

    verifyCertificationStatus() {
        if (this.userInfo.verifyStatus.id === 0) {
            let modal = this.modalSrv.create({
                nzTitle: "信息提示",
                nzContent: SharedPromptCertificationComponent,
                nzFooter: null,
                nzMaskClosable: false
            });
            setTimeout(() => {
                let cmp = modal.getContentComponent();
                cmp.closeEvent.subscribe(() => { modal.destroy(); });
            }, 1);
            return false;
        }
        else {
            return true;
        }
    }
}