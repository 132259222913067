import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { of } from 'rxjs';
import { delay } from 'rxjs/operators';
import { PathsUtil } from 'src/app/util/paths';

@Injectable()
export class FullScreenForgetPwdService {
    constructor(private http: HttpClient) {

    }
    checkAccount(params) {
        return this.http.get(PathsUtil.user_checkaccount, { params: params });
    }
    checkAnswer(params) {
        return this.http.get(PathsUtil.user_verifyqa, { params: params });
    }
    resetPwd(params) {
        return this.http.post(PathsUtil.user_resetpwd, params);
    }
}