import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { NZ_I18N, zh_CN, NZ_MESSAGE_CONFIG } from 'ng-zorro-antd';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { registerLocaleData } from '@angular/common';
import zh from '@angular/common/locales/zh';

import { CoreModule } from './core';
import { LayoutModule } from './layout';
import { RoutesModule } from './routes';
import { DefaultInterceptor } from './providers';
import { SharedModule } from './shared';
import { GlobalService } from './providers/global.service';
import { RouterModule } from '@angular/router';

registerLocaleData(zh);

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    SharedModule,
    RouterModule,
    CoreModule,
    LayoutModule,
    RoutesModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS, useClass: DefaultInterceptor, multi: true,
    },
    {
      provide: NZ_I18N, useValue: zh_CN
    },
    {
      provide: NZ_MESSAGE_CONFIG,
      useValue: {
        nzDuration: 3000,
        nzMaxStack: 1
      }
    },
    GlobalService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
