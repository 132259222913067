import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuardService } from '../providers';
import { LayoutDefaultComponent } from '../layout/default';
import { LayoutPassportComponent } from '../layout/passport';
import { LayoutFullScreenComponent } from '../layout/fullscreen';
//passport
import { PassportLoginComponent } from './passport/login';
import { PassportRegisterComponent } from './passport/register';
//fullscreen
import { FullScreenForgetPwdComponent } from './fullscreen/forgetpwd';
import { FullScreenChangePwdComponent } from './fullscreen/changepwd';
import { FullScreenEbayAuthComponent } from './fullscreen/ebayauth/ebayauth.component';
import { FullscreenPrivacyPolicyComponent } from './fullscreen/privacypolicy/privacypolicy.component';

const routes: Routes = [
    {
        path: '',
        component: LayoutDefaultComponent,
        canActivate: [AuthGuardService],
        canActivateChild: [AuthGuardService],
        children: [
            { path: '', pathMatch: 'full', redirectTo: '/home' },
            { path: 'home', loadChildren: '../view/home/home.module#HomeModule' },
            { path: 'account', loadChildren: '../view/account/account.module#AccountModule' },
            { path: 'transaction', loadChildren: '../view/transaction/transaction.module#TransactionModule' },
            { path: 'message', loadChildren: '../view/message/message.module#MessageModule' },
            { path: 'help', loadChildren: '../view/help/help.module#HelpModule' },
            { path: 'application', loadChildren: '../view/application/application.module#ApplicationModule' }
        ]
    },
    {
        path: 'passport',
        component: LayoutPassportComponent,
        children: [
            { path: '', pathMatch: 'full', redirectTo: '/passport/login' },
            { path: 'login', component: PassportLoginComponent },
            { path: 'register', component: PassportRegisterComponent },
        ]
    },
    {
        path: 'fullscreen',
        component: LayoutFullScreenComponent,
        children: [
            { path: '', pathMatch: 'full', redirectTo: '/fullscreen/forgetpwd' },
            { path: 'forgetpwd', component: FullScreenForgetPwdComponent },
            { path: 'changepwd', component: FullScreenChangePwdComponent },
            { path: 'ebayauth', component: FullScreenEbayAuthComponent },
            { path: 'privacypolicy', component: FullscreenPrivacyPolicyComponent },
        ]
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { useHash: true })],
    exports: [],
    providers: []
})
export class AppRoutingModule { }