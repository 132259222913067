import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { PathsUtil } from '../../../util/paths';

@Injectable()
export class FullScreenEbayAuthService {
    constructor(private http: HttpClient) {

    }
    eBayUserToken(params: any) {
        return this.http.post(PathsUtil.app_ebayusertoken, params);
    }
}