import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-shared-prompt-frozen',
    templateUrl: './frozen.component.html',
    styleUrls: ['./frozen.component.less']
})
export class SharedPromptFrozenComponent implements OnInit {
    constructor() { }

    ngOnInit(): void { }
}
