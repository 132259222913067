import { Injectable } from '@angular/core';
import { AESTicket } from '../util/aes-ticket';

@Injectable({
    providedIn: 'root'
})
export class CacheService {
    getCache(key: string, isDescrypt: boolean = false) {
        var value = localStorage.getItem(key);
        if (isDescrypt) {
            return AESTicket.Descrypt(value);
        }
        else {
            return value;
        }
    }

    setCache(key: string, value: any, isEncrypt: boolean = false) {
        if (typeof (value) === 'object') {
            value = JSON.stringify(value);
        }
        if (isEncrypt) {
            localStorage.setItem(key, AESTicket.Encrypt(value));
        }
        else {
            localStorage.setItem(key, value);
        }
    }

    remove(key: string) {
        localStorage.removeItem(key);
    }

    clear() {
        localStorage.clear();
    }

    getAppLogo(id: string) {
        let appInfos = this.getCache('appInfos');
        if (appInfos) {
            appInfos = JSON.parse(appInfos);
            let appInfo = appInfos.find(item => item.id === id);
            if (appInfo) {
                return appInfo.logo;
            }
        }
        return null;
    }
}