import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'app-shared-prompt-certification',
    templateUrl: './certification.component.html',
    styleUrls: ['./certification.component.less']
})
export class SharedPromptCertificationComponent implements OnInit {
    @Output() closeEvent: EventEmitter<any> = new EventEmitter();
    constructor(private router: Router) { }

    ngOnInit(): void { }

    closeCertificationModal() {
        this.closeEvent.emit();
    }

    toCertification() {
        this.closeCertificationModal();
        // this.router.navigateByUrl('/account/personal/certification');
    }
}
