import { NgModule } from '@angular/core';
import { SharedModule } from '../shared';
import { SharedPipeModule } from '../shared/pipe';
import { AppRoutingModule } from './routes.routing.module';
import { PassportLoginComponent } from './passport/login';
import { PassportRegisterComponent } from './passport/register';
import { FullScreenForgetPwdComponent } from './fullscreen/forgetpwd';
import { FullScreenChangePwdComponent } from './fullscreen/changepwd';
import { FullScreenEbayAuthComponent } from './fullscreen/ebayauth/ebayauth.component';
import { FullscreenPrivacyPolicyComponent } from './fullscreen/privacypolicy/privacypolicy.component';
import { SharedAgreementModule } from '../shared/components/agreement/agreement.module';
import { RouterModule } from '@angular/router';

@NgModule({
    declarations: [
        PassportLoginComponent,
        PassportRegisterComponent,
        FullScreenForgetPwdComponent,
        FullScreenChangePwdComponent,
        FullScreenEbayAuthComponent,
        FullscreenPrivacyPolicyComponent
    ],
    imports: [
        AppRoutingModule,
        RouterModule,
        SharedModule,
        SharedAgreementModule,
        SharedPipeModule,
    ],
    exports: [],
    providers: [],
})
export class RoutesModule { }