import { Component } from '@angular/core';
import { Router, RouterEvent, NavigationStart, NavigationEnd, NavigationCancel, NavigationError } from '@angular/router';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.less']
})
export class AppComponent {
    isSpinning: boolean = false;
    constructor(
        private router: Router
    ) {
        this.router.events.subscribe((event: RouterEvent) => {
            this.navigationInterceptor(event);
        })
    }

    navigationInterceptor(event: RouterEvent): void {
        if (event instanceof NavigationStart) {
            this.isSpinning = true;
        }
        if (event instanceof NavigationEnd) {
            this.hideSpinner();
        }
        if (event instanceof NavigationCancel) {
            this.hideSpinner();
        }
        if (event instanceof NavigationError) {
            this.hideSpinner();
        }
    }
    hideSpinner(): void {
        this.isSpinning = false;
    }
}
