import { Injectable, Injector, ErrorHandler } from '@angular/core';
import { Router } from '@angular/router';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpErrorResponse, HttpEvent, HttpResponseBase, HttpResponse } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { mergeMap, catchError, retry, timeout } from 'rxjs/operators';
import { NzMessageService } from 'ng-zorro-antd';
import { environment } from '../../environments';
import { LoaderService } from '../core/loader';
import { Sign } from '../util/sign';
import { CacheService } from '../providers/cache.service';

const CODEMESSAGE = {
    200: '服务器成功返回请求的数据。',
    201: '新建或修改数据成功。',
    202: '一个请求已经进入后台排队（异步任务）。',
    204: '删除数据成功。',
    400: '发出的请求有错误，服务器没有进行新建或修改数据的操作。',
    401: '登录超时，请您重新登录。',
    403: '用户得到授权，但是访问是被禁止的。',
    404: '发出的请求针对的是不存在的记录，服务器没有进行操作。',
    406: '请求的格式不可得。',
    410: '请求的资源被永久删除，且不会再得到的。',
    422: '当创建一个对象时，发生一个验证错误。',
    500: '程序开小差了，请重新尝试或联系平台客服处理！',
    502: '网关错误。',
    503: '服务不可用，服务器暂时过载或维护。',
    504: '网关超时。'
};

/**
 * 默认HTTP拦截器，其注册细节见 `core.module.ts`
 */
@Injectable()
export class DefaultInterceptor implements HttpInterceptor {
    constructor(private injector: Injector, private loaderSrv: LoaderService, private cacheSrv: CacheService) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        this.loaderSrv.show();
        // 统一加上服务端前缀
        let url = req.url;
        if (!url.startsWith('https://') && !url.startsWith('http://')) {
            url = environment.host + url;
        }
        //从缓存中获取token
        let token = this.cacheSrv.getCache('t', true);
        let headers = req.headers;
        if (token) {
            headers = headers.append('Authorization', 'Bearer ' + token);
        }

        //请求URL中如果不包含upload，则设置请求头的Content-Type为application/json
        if (url.indexOf('upload') === -1) {
            headers = headers.append('Content-Type', 'application/json');
        }

        //当请求头的Content-Type为application/json时，对参数进行签名
        let params = req.params;
        if (headers.get('Content-Type') === 'application/json') {
            let nonce = Date.now();
            let obj: any = {};
            switch (req.method) {
                case 'POST':
                    Object.assign(obj, req.body);
                    break;
                case 'GET':
                    let keys = req.params.keys();
                    if (keys.length > 0) {
                        for (const key in keys) {
                            if (req.params.get(keys[key]) === null || req.params.get(keys[key]) === '' || req.params.get(keys[key]) === undefined) {
                                params = params.delete(keys[key]);
                            }
                            else {
                                obj[keys[key]] = req.params.get(keys[key]);
                            }
                        }
                    }
                    break;
                default:
                    break;
            }
            params = params.append('sign', Sign.GetSign(obj, nonce)).append('nonce', nonce.toString());
        }

        const newReq = req.clone({
            url: url,
            headers: headers,
            params: params
        });
        return next.handle(newReq).pipe(
            timeout(3 * 60000),
            mergeMap((event: any) => {
                // 允许统一对请求错误处理
                if (event instanceof HttpResponseBase)
                    return this.handleData(event);
                // 若一切都正常，则后续操作
                return of(event);
            }),
            catchError((err: HttpErrorResponse) => {
                return this.handleError(err);
            }),
        );
    }

    private handleData(ev: HttpResponseBase): Observable<any> {
        this.loaderSrv.hide();
        if (ev instanceof HttpResponse) {
            const body: any = ev.body;
            if (body.code >= 400) {
                switch (body.code) {
                    case 401:
                        this.cacheSrv.clear();
                        this.goTo('/passport/login');
                        break;
                    default:
                        break;
                }
                return throwError(new HttpErrorResponse({ status: body.code, statusText: body.data }));
            }
            if (body.sign) {
                let sign = Sign.GetSign(body);
                if (body.sign !== sign) {
                    return throwError(new Error('客户端验签失败'));
                }
            }
            if (!body.ok) {
                this.msg.error(body.data);
            }
            // 重新修改 `body` 内容为 `response` 内容，对于绝大多数场景已经无须再关心业务状态码
            return of(new HttpResponse(Object.assign(ev, { body: body })));
        }
        return of(ev);
    }

    private handleError(error: Error | HttpErrorResponse) {
        this.loaderSrv.hide();
        if (error instanceof HttpErrorResponse) {
            if (!navigator.onLine) {
                this.msg.error('请确认您的网络连接是否正常!');
                return throwError(error);
            } else {
                let errortext = CODEMESSAGE[error.status];
                switch (error.status) {
                    case 401: // 未登录状态码
                        this.cacheSrv.clear();
                        this.goTo('/passport/login');
                        break;
                    case 403:
                    case 404:
                    case 500:
                        //this.goTo(`/exception/${error.status}`);
                        break;
                    default:
                        errortext = `${error.message}`;
                        break;
                }
                this.msg.error(`${errortext}`);
                return throwError(error);
            }
        }
        else {
            this.msg.error(`${error.message}`);
            return throwError(error);
        }
    }

    get msg(): NzMessageService {
        return this.injector.get(NzMessageService);
    }

    private goTo(url: string) {
        setTimeout(() => this.injector.get(Router).navigateByUrl(url));
    }
}