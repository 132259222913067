import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'enterpriseType' })
export class SharedEnterpriseTypePipe implements PipeTransform {
    transform(value: any[], exponent: number): any {
        if (value) {
            return value.filter((item) => {
                return item.ownership.id === exponent;
            });
        }
        else {
            return [];
        }
    }
}