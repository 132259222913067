import { NgModule } from '@angular/core';
import { SharedModule } from '../shared.module';
import { SharedEnterpriseTypePipe } from './enterprisetype.pipe';
import { SharedFormatTelPipe } from './formattel.pipe';
import { SharedSafeHtmlPipe } from './safehtml.pipe';
import { SharedSafeUrlPipe } from './safeurl.pipe';
import { SharedFormatCardIDPipe } from './formatcard.pipe';
import { SharedEnterpriseNamePipe } from './enterprisename.pipe';

@NgModule({
    declarations: [
        SharedEnterpriseTypePipe,
        SharedFormatTelPipe,
        SharedSafeHtmlPipe,
        SharedSafeUrlPipe,
        SharedFormatCardIDPipe,
        SharedEnterpriseNamePipe
    ],
    imports: [
        SharedModule
    ],
    exports: [
        SharedEnterpriseTypePipe,
        SharedFormatTelPipe,
        SharedSafeHtmlPipe,
        SharedSafeUrlPipe,
        SharedFormatCardIDPipe,
        SharedEnterpriseNamePipe
    ]
})
export class SharedPipeModule { }