import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { FullScreenChangePwdService } from './changepwd.service';
import { NzMessageService } from 'ng-zorro-antd';
import { CacheService } from '../../../providers/cache.service';
import { AESTicket } from 'src/app/util/aes-ticket';
import { Router } from '@angular/router';


@Component({
    selector: 'app-fullscreen-changepwd',
    templateUrl: './changepwd.component.html',
    styleUrls: ['./changepwd.component.less'],
    providers: [FullScreenChangePwdService]
})
export class FullScreenChangePwdComponent implements OnInit {
    current = 0;
    account: string = '';
    personInfo: any;//用户信息
    passwordForm: FormGroup;
    private interval: any;
    constructor(
        private fb: FormBuilder,
        private srv: FullScreenChangePwdService,
        private msgSrv: NzMessageService,
        private cacheSrv: CacheService,
        private router: Router
    ) { }

    ngOnInit(): void {
        console.log(this.account);
        this.passwordForm = this.fb.group({
            password: ['', [Validators.required, Validators.pattern(/^(?![\d]+$)(?![a-zA-Z]+$)(?![^\da-zA-Z]+$).{8,}$/)]],
            newPassword: ['', [Validators.required, Validators.pattern(/^(?![\d]+$)(?![a-zA-Z]+$)(?![^\da-zA-Z]+$).{8,}$/)]],
            checkPassword: ['', [Validators.required, this.confirmationValidator]]
        });
    }


    updateConfirmValidator(): void {
        Promise.resolve().then(() => this.passwordForm.controls.checkPassword.updateValueAndValidity());
    }

    confirmationValidator = (control: FormControl): { [s: string]: boolean } => {
        if (!control.value) {
            return { required: true };
        } else if (control.value !== this.passwordForm.controls.newPassword.value) {
            return { confirm: true, error: true };
        }
    }

    done(): void {
        this.srv.updatePwd({
            account:this.cacheSrv.getCache('account'),
            pwd: AESTicket.Encrypt(this.passwordForm.get('password').value),
            newPwd: AESTicket.Encrypt(this.passwordForm.get('checkPassword').value),
        }).subscribe((resp: any) => {
            if (resp.ok) {
                this.msgSrv.success("修改成功!");
                this.router.navigateByUrl('/passport/login');
            }
        })
    }
}
