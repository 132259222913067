import { Component, OnInit, TemplateRef } from '@angular/core';
import { NzModalService } from 'ng-zorro-antd';

@Component({
    selector: 'app-shared-agreement-serve',
    templateUrl: './serve.component.html',
    styleUrls: ['./serve.component.less']
})
export class SharedAgreementServeComponent implements OnInit {
    constructor(private modalSrv: NzModalService) { }

    ngOnInit(): void { }

    showAgreement(tplContent: TemplateRef<{}>) {
        this.modalSrv.create({
            nzContent: tplContent,
            nzMaskClosable: true,
            nzWidth: 1000,
            nzFooter: null
        });
    }
}
