import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'enterpriseName' })
export class SharedEnterpriseNamePipe implements PipeTransform {
    transform(value: any[], exponent: string): any {
        if (value) {
            return value.filter((item) => {
                if (exponent === '' || item.name.indexOf(exponent) !== -1) {
                    return true;
                }
                return false;
            });
        }
        else {
            return [];
        }
    }
}