import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-layout-fullscreen',
    templateUrl: './fullscreen.component.html',
    styleUrls: ['./fullscreen.component.less']
})
export class LayoutFullScreenComponent implements OnInit {
    constructor() { }

    ngOnInit(): void { }

    navigate(path: string) {
        let url = `https://www.windpayer.com/${path}.html`;
        window.open(url);
    }
}
