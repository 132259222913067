import { Component, OnInit, TemplateRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators, AbstractControl, ValidationErrors, FormControl } from '@angular/forms';
import { Observable, timer } from 'rxjs';
import { NzMessageService, NzModalService } from 'ng-zorro-antd';
import { debounceTime, distinctUntilChanged, switchMap, map } from 'rxjs/operators';
import { RegisterService } from './register.service';
import { AESTicket } from '../../../util/aes-ticket';
import { CacheService } from '../../../providers/cache.service';
import { VerificationService } from 'src/app/providers/verification.service';

@Component({
    selector: 'app-passport-register',
    templateUrl: './register.component.html',
    styleUrls: ['./register.component.less'],
    providers: [RegisterService]
})
export class PassportRegisterComponent implements OnInit {
    isInvitationCodeReadOnly: boolean = false;;
    isLoading: boolean = false;
    validateForm: FormGroup;
    messageCode_second: number = 60;//再次获取验证码的间隔时间(s)
    noticeFlag: boolean = false;
    private messageCode: string = '';//手机验证码
    private messageCode_expried: number = 0;//验证码过期时间
    constructor(
        private fb: FormBuilder,
        private srv: RegisterService,
        private msgSrv: NzMessageService,
        private router: Router,
        private verifySrv: VerificationService,
        private cacheSrv: CacheService,
        private activatedRoute: ActivatedRoute,
        private route: ActivatedRoute
    ) { }

    ngOnInit(): void {
        this.route.queryParams.subscribe(queryParams => {
            if (queryParams.invitationCode) {
                window.location.href = `https://account.windpayer.com/register?inviteCode=${queryParams.invitationCode}`;
            } else {
                window.location.href = `https://account.windpayer.com/#/register`;
            }
        });

        this.validateForm = this.fb.group({
            phone: [null, [Validators.required, Validators.pattern(/^1[3|4|5|6|7|8|9][0-9]\d{8}$/)], [this.phoneAsyncValidator.bind(this)]],
            messageCode: [null, [Validators.required]],
            password: [null, [Validators.required, Validators.pattern(/^(?![\d]+$)(?![a-zA-Z]+$)(?![^\da-zA-Z]+$).{8,}$/)]],
            checkPassword: [null, [Validators.required, this.confirmationValidator]],
            invitationCode: [''],
            agree: [false]
        });
        this.activatedRoute.queryParams.subscribe((resp: { invitationCode: string }) => {
            if (resp.invitationCode) {
                this.isInvitationCodeReadOnly = true;
                this.validateForm.patchValue({
                    invitationCode: resp.invitationCode
                });
            }
        });
    }

    phoneAsyncValidator(control: AbstractControl): Observable<ValidationErrors | null> {
        return timer(300).pipe(
            debounceTime(500),
            distinctUntilChanged(),
            switchMap(() => {
                let phone = this.validateForm.get('phone').value;
                return this.srv.check(phone).pipe(
                    map((resp: any) => {
                        if (resp.ok) {
                            return null;
                        }
                        else {
                            if (resp.code === 200) {
                                return { error: true, duplicated: true };
                            }
                        }
                    })
                );
            })
        );
    }
    updateConfirmValidator(): void {
        Promise.resolve().then(() => this.validateForm.controls.checkPassword.updateValueAndValidity());
    }
    confirmationValidator = (control: FormControl): { [s: string]: boolean } => {
        if (!control.value) {
            return { required: true };
        } else if (control.value !== this.validateForm.controls.password.value) {
            return { confirm: true, error: true };
        }
    }
    getMessageCode() {
        this.messageCode_second = 59;
        let interval = window.setInterval(() => {
            if (this.messageCode_second <= 0) {
                window.clearInterval(interval);
                this.messageCode_second = 60;
                return;
            }
            else {
                this.messageCode_second--;
            }
        }, 1000);
        let phone = this.validateForm.get("phone").value;
        this.verifySrv.sendVerifyCode({
            what: 'SignUp_VerifyCode',
            tel: phone
        }).subscribe((resp: any) => {
            if (resp.ok) {
                let data = JSON.parse(AESTicket.Descrypt(resp.data));
                this.messageCode = data.code;
                this.messageCode_expried = data.expried;
                return;
            }
        });
    }
    register() {
        let data = this.validateForm.value;
        if (data.messageCode !== this.messageCode) {
            this.msgSrv.error("请输入正确的手机验证码!");
            return false;
        }
        this.isLoading = true;
        this.srv.register({
            tel: data.phone,
            pwd: AESTicket.Encrypt(data.password),
            invitationCode: data.invitationCode,
            code_expried: this.messageCode_expried
        }).subscribe((resp: any) => {
            this.isLoading = false;
            if (resp.ok) {
                let data = JSON.parse(AESTicket.Descrypt(resp.data));
                this.cacheSrv.setCache('t', data.token, true);
                this.cacheSrv.setCache('s', data.secret, true);
                this.router.navigateByUrl('/home');
            }
        }, () => {
            this.isLoading = false;
        });
    }
}
