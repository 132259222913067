import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { LoginService } from './login.service';
import { AESTicket } from 'src/app/util/aes-ticket';
import { CacheService } from '../../../providers/cache.service';
import { NzMessageService } from 'ng-zorro-antd';

@Component({
    selector: 'app-passport-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.less'],
    providers: [LoginService]
})
export class PassportLoginComponent implements OnInit {
    @ViewChild('checkCode') canvasRef: ElementRef;
    validateForm: FormGroup;
    isLoading: boolean = false;
    showVerificationCode: boolean = false;
    code: string;
    flag: number = 0;
    constructor(
        private fb: FormBuilder,
        private srv: LoginService,
        private router: Router,
        private cacheSrv: CacheService,
        private msgSrv: NzMessageService
    ) { }

    ngOnInit(): void {
        this.validateForm = this.fb.group({
            userName: [null, [Validators.required]],
            password: [null, [Validators.required]],
            code: ['']
        });
        
    }

    login() {
        let formData = this.validateForm.value;
        if (this.showVerificationCode && formData.code.toLocaleLowerCase() !== this.code.toLocaleLowerCase()) {
            this.msgSrv.error("验证码错误");
            this.clickChange();
            return false;
        }
        this.isLoading = true;
        this.srv.login({
            account: formData.userName,
            pwd: AESTicket.Encrypt(formData.password)
        }).subscribe((resp: any) => {
            this.isLoading = false;
            if (resp.ok) {
                this.flag = 0;
                this.showVerificationCode = false;
                let data = JSON.parse(AESTicket.Descrypt(resp.data));
                if (data.msg != undefined) {
                    this.cacheSrv.setCache('account', formData.userName);
                    this.msgSrv.warning(data.msg);
                    this.router.navigateByUrl('/fullscreen/changepwd');
                }
                else {
                    this.cacheSrv.setCache('t', data.token, true);
                    this.cacheSrv.setCache('s', data.secret, true);
                    this.router.navigateByUrl('/home');
                }
            }
            else {
                this.flag++;
                if (this.flag >= 3) {
                    this.showVerificationCode = true;
                    this.clickChange();
                    this.validateForm.get('code').setValidators(Validators.required);
                    this.validateForm.get('code').updateValueAndValidity();
                }

            }
        }, () => {
            this.isLoading = false;
        });
    }

    public clickChange() {
        const cxt: CanvasRenderingContext2D = this.canvasRef.nativeElement.getContext('2d');
        cxt.fillStyle = '#fff';
        cxt.fillRect(0, 0, 80, 35);

        /*生成干扰线20条*/
        for (let j = 0; j < 20; j++) {
            cxt.strokeStyle = this.rgb();
            cxt.beginPath();    // 若省略beginPath，则每点击一次验证码会累积干扰线的条数
            cxt.moveTo(this.lineX(), this.lineY());
            cxt.lineTo(this.lineX(), this.lineY());
            cxt.lineWidth = 0.5;
            cxt.closePath();
            cxt.stroke();
        }

        cxt.fillStyle = '#6271a9';
        cxt.font = 'bold 20px Arial';
        cxt.fillText(this.createCode(), 15, 25);   // 把rand()生成的随机数文本填充到canvas中
    }

    // 创建随机码
    public createCode() {
        this.code = '';
        const codeLength = 4;  // 验证码的长度
        const random = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9,
            'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z',
            'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z']; // 所有候选组成验证码的字符，当然也可以用中文的
        for (let i = 0; i < codeLength; i++) { // 循环操作
            const index = Math.floor(Math.random() * 52); // 取得随机数的索引（0~51）
            this.code += random[index]; // 根据索引取得随机数加到code上
        }
        return this.code;
    }

    /*干扰线的随机x坐标值*/
    public lineX() {
        const ranLineX = Math.floor(Math.random() * 80);
        return ranLineX;
    }

    /*干扰线的随机y坐标值*/
    public lineY() {
        const ranLineY = Math.floor(Math.random() * 35);
        return ranLineY;
    }

    // 生成随机颜色
    public rgb() {
        // 因为在angular4里面不能使用arguments，所以换了一种方法。
        return '#' + Math.floor(Math.random() * 16777215).toString(16);
    }
}
