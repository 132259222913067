import { Component, AfterViewInit } from '@angular/core';

@Component({
    selector: 'app-layout-passport',
    templateUrl: './passport.component.html',
    styleUrls: ['./passport.component.less']
})
export class LayoutPassportComponent implements AfterViewInit {
    constructor() { }

    ngAfterViewInit(): void {

    }
    navigate(path: string) {
        let url = `https://www.windpayer.com/${path}.html`;
        window.open(url);
    }
}
